import qs from 'qs';
import { isEmpty } from 'validate.js';

import { removeEmptyValues } from 'dpl/shared/utils/object';
import { ANALYTICS } from 'dpl/constants/query_params';
import { isMobileUA } from 'dpl/shared/utils';

const IS_MOBILE = isMobileUA();

export function openPuppyPage(
  { puppyId, locName, gender, analytics, expand },
  target
) {
  const queryStr = qs.stringify(
    removeEmptyValues({
      [ANALYTICS]: !isEmpty(analytics) ? analytics : null,
      locName: !isEmpty(locName) ? locName : null,
      gender: !isEmpty(gender) ? gender : null
    })
  );

  let pageUrl = [`/puppy/${puppyId}`, queryStr].filter(Boolean).join('?');

  if (expand === false) {
    pageUrl = `${pageUrl}#expand=false`;
  }
  window.open(pageUrl, target || (IS_MOBILE ? '_self' : '_blank'));
}

export function getRequestInfoCtaLabels({ pageVariant }) {
  if (pageVariant === 'variant_2') {
    return { primary: 'Contact breeder' };
  }

  return { primary: 'Request info' };
}

export function getMapCoordinates({ breeder, buyer }) {
  if (isEmpty(breeder.location)) return [];

  return [
    { lat: breeder.location.latitude, lng: breeder.location.longitude },
    !isEmpty(buyer.location) &&
      buyer.location.distance_in_miles <= 200 && {
        lat: buyer.location.latitude,
        lng: buyer.location.longitude
      }
  ].filter(Boolean);
}
